.wrapper {
  position: absolute;
  bottom: 0;
  width: 100vw;
}

.colorControllerPanel {
  overflow-x: scroll;
  height: 110px;
  width: 100vw;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #aaa;
  transition: all 0.3s;
  font-weight: bold;
}

.colorControllerPanel::-webkit-scrollbar {
  display: none;
}

.colorControllerPanel.isCollapsed {
  bottom: -72px;
}

.toggleCollapseButton {
  width: 100%;
  height: 5px;
  background: #fff;
  cursor: ns-resize;
  display: none;
}

.colorControllers {
  width: 1500px;
  height: 100%;
  white-space: nowrap;
}

.colorControllerContainer {
  display: inline-block;
  height: 100%;
  width: 310px;
  min-width: 20vw;
  /*border: 1px solid #fff;*/
}
