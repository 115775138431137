.checkboxButton {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

.inputWrapper {
  height: 100%;
}

.checkboxLabel {
  user-select: none;
  display: grid;
  align-items: center;
  height: 100%;
  width: 100%;
  color: #fff;
  cursor: pointer;
  padding: 0 7px;
  text-align: center;
  font-size: 1em;
  line-height: 1;
  border: 1px solid transparent;
}
