* {
  box-sizing: border-box;
}

html {
  color: #242424;
  font-size: 1em;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: auto;
  width: 100%;
  height: 100%;
  font-family: 'Inconsolata', monospace;
  font-size: 0.8em;
}

button {
  outline: none;
  background: none;
  border: none;
}

a {
  color: #242424;
}

.textLink {
  text-decoration: underline !important;
  color: #242424;
  background: rgba(0, 0, 0, 0.09);
}

.textLink:hover {
  text-decoration: underline;
  color: #242424;
  font-weight: bold;
}

/* ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
}

ul li {
  font-size: 12px;
} */

.text-gray {
  color: #888;
}

#holder {
  background-color: #eeeeee;
  height: calc(100vh - 80px);
  width: 100%;
}

#holder canvas {
  height: 100%;
}
#holder:focus {
  outline: none;
}

/* ========================================================================== */
div[tabindex='-1']:focus {
  outline: 0;
}

.rc-slider-vertical,
.rc-slider {
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
  border-radius: 0;
}

.rc-slider-vertical .rc-slider-mark {
  display: none;
}

.rc-slider:hover .rc-slider-track {
  filter: brightness(1.04);
}

.github-picker {
  position: static !important;
  border: none !important;
  border-radius: 0 !important;
  padding: 3px !important;
  width: 100% !important;
  height: 31px !important;
  text-align: center !important;
  z-index: 100;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.11) !important;
  background: white !important;
}

.github-picker span {
  width: 20% !important;
  height: 100% !important;
}

.github-picker span div {
  width: 100% !important;
}

.ant-popover.ant-popover-placement-rightTop {
  z-index: 2000;
}

.ant-popover-placement-bottomRight .ant-popover-inner-content {
  padding: 0;
}

.ant-tooltip {
  font-weight: bold;
}

.ant-drawer-title,
.ant-drawer-close {
  color: inherit;
}

/* .ant-popover-inner {
  background-color: rgb(110, 110, 110);
  color: #eeeeee;
} */

/*  ==================== Darkmode changes ==================== */

/* .styles_toolbar__mu6A3 {
  background: #343434;
}

.styles_headerMenu__2aEMl {
  background: #2e2e2e;
  color: #ddd;
}

.styles_headerMenu__2aEMl {
  border-bottom: 1px solid #252525;
}

body {
  background: #323232;
}

.styles_ProjectCard__32opC {
  background: #353535;
  border: 1px solid #2e2a2a;
  color: #eee;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(230, 219, 219, 0.85);
}

a {
  color: #e7dada;
} */
