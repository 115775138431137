.downloads li {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 4px;
  background: #eee;
}
.downloads ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.downloads audio {
  display: block;
  margin-bottom: 10px;
  width: 100%;
}

.downloads a {
  display: block;
}
