.ShapeEditorPopover {
  position: absolute;
  width: 225px;
  height: 260px;
  background: #fff;
  display: grid;
  grid-gap: 2px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 30px auto 1fr 1fr 30px 30px;
  box-shadow: 1px 2px 19px rgba(0, 0, 0, 0.3);
}

.thirds {
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 2px;

  grid-template-columns: repeat(3, 1fr);
  grid-column: 2 / 4;
}

.sliderContainer {
  grid-row: 1 / 7;
}

.ShapeEditorPopover button {
  height: 100%;
  background: none;
  border: none;
  background: #c9563c;
  color: #fff;
}

.tooltipArrow {
  display: block;
  position: absolute;
  top: 40px;
  content: ' ';
  height: 0;
  width: 0;
  pointer-events: none;
  border: 10px solid rgba(255, 255, 255, 0);
  margin-top: -10px;
}

.buttonShort {
  height: 30px;
}

.deleteButton,
.duplicateButton,
.colorPickerContainer {
  grid-column: 2 / 4;
  text-align: center;
  color: #fff;
  padding: 5px;
}

.colorPickerContainer {
  overflow: hidden;
  padding: 0;
  transition: height 0.2s;
}

.colorPickerContainer button {
  width: 100%;
  height: 30px;
}

.arrowLeft {
  right: 100%;
  border-right-color: #fff;
}

.arrowRight {
  left: 100%;
  border-left-color: #fff;
}
