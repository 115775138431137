.ProjectTitle {
  display: inline-block;
  max-width: 300px;
  text-align: center;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 5px;
}
