.toolbar {
  display: grid;
  z-index: 2;
  grid-template-rows: 100%;
  grid-column-gap: 15px;
  grid-template-columns: 80px 120px 270px 100px 200px 120px 250px 120px 1fr;
  min-width: 800px;
  top: 0px;
  height: 50px;
  padding: 8px 6px;
  width: 100%;
  background: #e5e5e5;
}

.colorPickerWrapper {
  width: 140px;
  left: 0px;
  top: 7px;
  height: 50px;
  position: relative;
  z-index: 500;
}

.toolbarSection {
  position: relative;
  display: grid;
  grid-template-rows: 100%;
  grid-gap: 3px;
  /*padding: 2px;*/
  border-radius: 8px;
  grid-template-columns: repeat(2, 1fr);
  /*background: #222;*/
}

.canvasControls {
  grid-template-columns: repeat(2, 1fr);
}

.toolSelect {
  grid-template-columns: repeat(3, calc(100% / 3));
}

.musicalControls {
  /*width: 250px;*/
  grid-template-columns: 70px 1fr;
}

.toolbar button {
  height: 100%;
  width: 100%;
}

.pulse {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
